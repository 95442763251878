import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-frame',
  templateUrl: './list-frame.component.html',
})
export class ListFrameComponent implements OnInit {
  @Input() title: string = "All Posts";


  constructor() { }

  ngOnInit() {
  }

}
