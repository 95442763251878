import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupFilterListComponent } from './group-filter-list.component';
import { RouterModule } from '@angular/router';
import { GroupSectionComponent } from './group-section/group-section.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [GroupFilterListComponent, GroupSectionComponent],
  exports: [GroupFilterListComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
  ]
})
export class GroupFilterListModule { }
