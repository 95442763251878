import { Component, OnInit } from '@angular/core';
import { GroupData } from '../../../components/group-filter-list/group-filter-list.model';

@Component({
  selector: 'app-group-list-testbed',
  templateUrl: './group-list-testbed.component.html',
  styleUrls: ['./group-list-testbed.component.scss']
})
export class GroupListTestbedComponent implements OnInit {
  private groupData: GroupData = {
    sections: [
      {name: 'Test Section Nr 1', desc: "Description 1 is not available", items:[
          {name: 'Test Item Nr 1 1', desc:"No Item Description", path:'/about'},
          {name: 'Test Item Nr 1 2', desc:"No Item Description", path:'/about'},
          {name: 'Test Item Nr 1 3', desc:"No Item Description", path:'/about'},
        ]
      },
      {name: 'Test Section Nr 2', desc: "Description 2 is not available", items:[
          {name: 'Test Item Nr 2 1', desc:"No Item Description", path:'/about'},
          {name: 'Test Item Nr 2 2', desc:"No Item Description", path:'/about'},
          {name: 'Test Item Nr 2 3', desc:"No Item Description", path:'/about'},
        ]
      },
      {name: 'Test Section Nr 3', desc: "Description 3 is not available", items:[
          {name: 'Test Item Nr 3 1', desc:"No Item Description", path:'/about'},
          {name: 'Test Item Nr 3 2', desc:"No Item Description", path:'/about'},
          {name: 'Test Item Nr 3 3', desc:"No Item Description", path:'/about'},
        ]
      },
    ]
  } ;

  constructor() { }

  ngOnInit() {

  }

}
