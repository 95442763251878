import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as marked from 'marked';

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  markedOptions: any ;

  constructor(private router:Router, private ngZone:NgZone) {
    let myrenderer = new marked.Renderer() ;
    myrenderer.link = MarkdownService.makeLink ;

    // @ts-ignore
    window.__contentHelper = {
      onLink: this.onLink.bind(this)
    } ;

    this.markedOptions = {
      renderer: myrenderer,
      pedantic: false,
      gfm: true,
      tables: true,
      breaks: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      xhtml: false
    };
  }

  private static makeLink(href, title, text):string {
    return `<a href="javascript:__contentHelper.onLink('${href}')">${text}</a>`
  }

  private onLink(href:string, title:string, text: string) {
    console.info ("onLink", href) ;
    if (href.toLowerCase().startsWith('http://') || href.toLowerCase().startsWith("https://"))
      window.open(href, '_blank') ;
    else
      this.ngZone.run(() => {
        this.router.navigate([href]) ;
      }) ;
  }

  public renderContent(markdown:string): any {
    return marked (markdown, this.markedOptions) ;
  }
}
