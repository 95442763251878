import { Injectable } from '@angular/core';
import { ContentDao } from '../dao/content.dao';
import { distinct, filter, flatMap, map, skip, take, tap, toArray } from 'rxjs/operators';
import * as matter from 'gray-matter';
import { MarkdownService } from './markdown.service';
import { Observable } from 'rxjs';
import { IndexEntry } from '../model/index-entry';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private contentDao: ContentDao, private markdownService: MarkdownService) {
  }

  public getContent(path: string, teaserOnly = false): Observable<any> {
    return this.contentDao.getContent(path+'.md').pipe(
      map((content) => matter(content)),
      map((content) => {
        let pos = -1 ;
        if (teaserOnly) {
          // find position of (first) snip comment
          pos = content.content.search("<!--.*snip.*-->") ;
        }

        const result = this.markdownService.renderContent(pos > 0 ? content.content.substring(0, pos) : content.content) ;
        return {
          data: content.data,
          content: result,
          isTeaser: pos > 0 as boolean
        };
      }),
      tap(
        (content) => console.debug("Service: Content:", content)
      )
    )
  }

  public getContentByType(type: string, name: string) {
    return this.getContent(type+'/'+name) ;
  }

  public getEntriesByType(typeName: string, taker = 9999, skipper = 0): Observable<Array<IndexEntry>> {
    return this.contentDao.getContentEntries().pipe(
      map(entries => entries.sort(ContentService.sortByDate)),
      flatMap(entries => entries),
      filter(entry => entry.type === typeName),
      skip(skipper), take(taker),
      toArray(),
    )
  }

  public getEntriesByCategory(categoryName: string, taker = 9999, skipper = 0): Observable<Array<IndexEntry>> {
    return this.contentDao.getContentEntries().pipe(
      flatMap(entries => entries),
      filter(entry => entry.category === categoryName),
      skip(skipper), take(taker),
      toArray(),
      map(entries => entries.sort(ContentService.sortByDate)),
    )
  }

  public getEntriesByTag(tagName: string, taker = 9999, skipper = 0): Observable<Array<IndexEntry>> {
    return this.contentDao.getContentEntries().pipe(
      flatMap(entries => entries),
      filter(entry => entry.tags.includes(tagName)),
      skip(skipper), take(taker),
      toArray(),
      map(entries => entries.sort(ContentService.sortByDate)),
    )
  }

  public getTags(): Observable<Array<String>> {
    return this.contentDao.getContentEntries().pipe(
      flatMap((entries) => entries),
      flatMap((entry) => entry.tags),
      distinct(),
      toArray(),
      map(entries => entries.sort(ContentService.sortAsc)),
      tap ((data) => console.log("XXX: 1: ", data)),
      )

  }

  public getCategories(): Observable<Array<String>> {
    return this.contentDao.getContentEntries().pipe(
      flatMap((entries) => entries),
      map((entry) => entry.category),
      distinct(),
      toArray(),
      map(entries => entries.sort(ContentService.sortAsc)),
    )
  }


  static sortByDate(a, b) {
    a = new Date(a.date);
    b = new Date(b.date);
    return a>b ? -1 : a<b ? 1 : 0;
  }

  static sortDesc(a, b) {
    return a>b ? -1 : a<b ? 1 : 0;
  }

  static sortAsc(a, b) {
    return a>b ? 1 : a<b ? -1 : 0;
  }

}
