import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';


import { Observable } from 'rxjs';
import { SiteConfig } from '../model/site-config';
import { IndexEntry } from '../model/index-entry';

@Injectable({
  providedIn: 'root'
})
export class ContentDao {
  private contentRoot = "_content" ;

  constructor(private http: HttpClient) {
  }

  getSiteConfig(): Observable<SiteConfig> {
    return this.http.get<SiteConfig>(this.makePath('site.json')).pipe(
      tap((data) => console.debug("got site-config", data))
    )
  }

  getContentEntries(): Observable<Array<IndexEntry>> {
    return this.http.get<Array<IndexEntry>>(this.makePath('content.json')).pipe(
      tap((data) => console.debug("got content entries", data))
    )

  }


  getContent(path: string): Observable<any> {
    let resource = this.makePath(path) ;
    return this.http.get(resource, {responseType: 'text'})
      .pipe(
        tap( // Log the result or error
          data => console.log(resource, data),
          error => console.error(resource, error)
        )
      );
  }

  getPosts(): Observable<any> {
    let resource = this.makePath("posts/content.json") ;
    return this.http.get(resource)
  }

  private makePath(path: string) {
    let result = this.contentRoot ;
    if (!path.startsWith('/'))
      result += "/" ;
    result += path ;

    return result ;
  }
}
