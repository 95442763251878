import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ajam-paginator',
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent implements OnInit {
  private _total: number = 0 ;
  @Input() private _items: number = 3 ;

  pages: Array<string> = [] ;

  private _currentPage: number = 0;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter() ;

  constructor() { }

  ngOnInit() {
    this.initPages(this._total, this._items)
  }

  initPages(total, items) {
    this.pages = [] ;
    if (total > 0 && items > 0) {
      let numPages = total / items ;
      for (let i = 0; i< numPages; i++)
        this.pages.push((i+1).toString())
    }
  }

  public selPage(idx: number) {
    this.currentPage = idx ;
  }

  @Input()
  public set total(value: number) {
    this._total = value;
    this.initPages(this._total, this._items)
  }

  @Input()
  public set items(value: number) {
    this._items = value;
  }

  public get currentPage(): number {
    return this._currentPage;
  }

  public set currentPage(value: number) {
    console.debug ("page selected: ", value) ;
    this._currentPage = value;
    this.pageChanged.next(value);
  }


  public next() {
    if (this.canNext())
      this.currentPage++ ;
  }

  public canNext():boolean {
    return (this.currentPage<this.pages.length-1)
  }

  public prev() {
    if (this.canPrev())
      this.currentPage-- ;
  }

  public canPrev():boolean {
    return this.currentPage>0
  }
}
