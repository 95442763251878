import { Component, OnInit } from '@angular/core';
// import CMS from 'netlify-cms';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor() {
    // @ts-ignore
    // window.CMS_MANUAL_INIT = true
  }

  ngOnInit() {
    // setTimeout(() => {
    //   const CMS = require("netlify-cms") ;
    //   CMS.init() ;
    // }, 2000)
  }

}
