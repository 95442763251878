import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../service/content.service';
import { count, flatMap, map, mergeAll, mergeMap, skip, switchMap, take, tap, toArray } from 'rxjs/operators';
import { Content } from '../../model/content';
import { of } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
})
export class BlogComponent implements OnInit {
  contents: Array<Content> ;

  entryType: string = "posts";
  itemsPerPage = 3 ;
  totalItems = 0 ;

  constructor(private contentService: ContentService,
              private domSanitizer: DomSanitizer,
  ) { }

  public ngOnInit(): void {
    this.contentService.getEntriesByType(this.entryType).pipe(
      flatMap(entries => entries),
      count()
    ).subscribe(
      (count => this.totalItems = count)
    ) ;

    this.updatePage(0) ;
  }

  public updatePage(page: number) {
    const entryPipe = (entry) => of(entry).pipe(
      switchMap((entry) => this.contentService.getContent(entry.path, true)),
      // tap((entry) => console.log ("XXXX entryPipe", entry)),
      map(content => {return {data: content.data, meta:entry, isTeaser: content.isTeaser, html:this.domSanitizer.bypassSecurityTrustHtml(content.content)}}),
    ) ;

    this.contentService.getEntriesByType(this.entryType, this.itemsPerPage, this.itemsPerPage*page).pipe(
      // tap((entries) => console.log ("XXXX got me some blog entries", entries)),
      flatMap((entries) => entries),
      mergeMap(entryPipe),
      toArray()
    ).subscribe(
      (contents) => {
        console.debug ("XXXX mergeMap Result", contents) ;
        this.contents = contents  ;
      }
    )

  }


  public ngOnInit2(): void {
    const entryPipe = (entry) => of(entry).pipe(
      switchMap((entry) => this.contentService.getContent(entry.path, true)),
      // tap((entry) => console.log ("XXXX entryPipe", entry)),
      map(content => {return {data: content.data, meta:entry, isTeaser: content.isTeaser, html:this.domSanitizer.bypassSecurityTrustHtml(content.content)}}),
    ) ;

    this.contentService.getEntriesByType("posts",3).pipe(
      // tap((entries) => console.log ("XXXX got me some blog entries", entries)),
      flatMap((entries) => entries),
      mergeMap(entryPipe),
      toArray()
    ).subscribe(
      (contents) => {
        console.debug ("XXXX mergeMap Result", contents) ;
        this.contents = contents  ;
      }
    )
  }

  public pageChanged(page: number) {
    console.debug("blog page changed!", page) ;
    this.updatePage(page) ;
  }


}
