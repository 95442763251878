
export class GroupData {
  sections: Array<GroupSection> = [] ;
}

export class GroupSection {
  name: string ;
  desc?: string ;

  items: Array<GroupItem> = [] ;
}

export class GroupItem {
  name: string ;
  path: string ;
  desc?: string = "" ;
  info?: string = "" ;
}
