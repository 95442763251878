import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../../../service/content.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  categories: Array<String> = [] ;
  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.contentService.getCategories()
      .subscribe((categories) => {
        this.categories = categories ;
      }) ;
  }

}
