import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dummy-component',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DummyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
