import { Component, OnInit } from '@angular/core';
import { ContentService } from '../../../../service/content.service';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {
  tags: Array<String> = [] ;
  constructor(private contentService: ContentService) { }

  ngOnInit() {
    this.contentService.getTags()
      .subscribe((tags) => {
        this.tags = tags
      }) ;
  }

}
