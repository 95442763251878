import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GroupData, GroupSection } from './group-filter-list.model';
import { GroupSectionComponent } from './group-section/group-section.component';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-filter-list.component.html',
})
export class GroupFilterListComponent implements OnInit, AfterViewInit {
  @Input() data: GroupData ;
  @Input() initialOpen: number = 2 ;

  @ViewChildren(GroupSectionComponent) sectionComponents: QueryList<GroupSectionComponent> ;

  constructor() { }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.sectionComponents.forEach((sc, index) => sc.expanded = index < this.initialOpen), 0)
  }



}
