import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { GroupSection } from '../group-filter-list.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-group-list-section',
  templateUrl: './group-section.component.html',
  animations: [
    trigger('openClose', [
      state('open', style({height: '*', opacity: 1,})),
      state('closed', style({height: '0', opacity: 0.5,})),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),

    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0', minHeight: '0', border:0 })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> void, expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ],
})
export class GroupSectionComponent implements OnInit {
  @Input() sectionData: GroupSection ;
  public expanded : boolean ;

  constructor() { }

  ngOnInit() {
  }
}
