import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupListTestbedComponent } from './group-list-testbed/group-list-testbed.component';
import { GroupFilterListModule } from '../../components/group-filter-list/group-filter-list.module';
import { DummyComponent } from './dummy/dummy.component';

@NgModule({
  declarations: [GroupListTestbedComponent, DummyComponent],
  imports: [
    CommonModule,
    GroupFilterListModule
  ]
})
export class SandboxModule { }
