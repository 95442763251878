import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';

$(() => {
  console.log ('Jquery version: ' + $.fn.jquery);
  let body = $('body') ;

  // @ts-ignore
  // body.tooltip({selector: '[data-toggle="tooltip"]'}) ;
  // @ts-ignore
  // body.popover({selector: '[data-toggle="popover"]'}) ;
}) ;

if (environment.production) {
  enableProdMode();
  if(window){
    window.console.log=function(){};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  defaultEncapsulation: ViewEncapsulation.None
})
  .catch(err => console.error(err));
