import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeEntryListComponent } from './type-entry-list/type-entry-list.component';
import { RouterModule } from '@angular/router';
import { TagEntryListComponent } from './tag/tag-entry-list/tag-entry-list.component';
import { CategoryEntryListComponent } from './category/category-entry-list/category-entry-list.component';
import { TagListComponent } from './tag/tag-list/tag-list.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { GroupFilterListModule } from '../../components/group-filter-list/group-filter-list.module';
import { ListFrameComponent } from './list-frame/list-frame.component';

@NgModule({
  declarations: [
    TypeEntryListComponent,
    TagEntryListComponent,
    CategoryEntryListComponent,
    TagListComponent,
    CategoryListComponent,
    ListFrameComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    GroupFilterListModule
  ]
})
export class ListModule { }
