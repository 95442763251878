import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentResolver } from './service/resolver/content-resolver';
import { PagePartComponent } from './parts/content/page/page-part.component';
import { TypeEntryListComponent } from './parts/list/type-entry-list/type-entry-list.component';
import { TypeEntryResolver } from './service/resolver/type-entry-resolver';
import { TagEntryListComponent } from './parts/list/tag/tag-entry-list/tag-entry-list.component';
import { TagEntryResolver } from './service/resolver/tag-entry-resolver';
import { CategoryEntryListComponent } from './parts/list/category/category-entry-list/category-entry-list.component';
import { CategoryEntryResolver } from './service/resolver/category-entry-resolver';
import { TagListComponent } from './parts/list/tag/tag-list/tag-list.component';
import { CategoryListComponent } from './parts/list/category/category-list/category-list.component';
import { BlogComponent } from './parts/blog/blog.component';
import { GroupListTestbedComponent } from './parts/sandbox/group-list-testbed/group-list-testbed.component';
import { DummyComponent } from './parts/sandbox/dummy/dummy.component';

const routes: Routes = [
  // {path:'', component:PagePartComponent, resolve:{content: ContentResolver}, data:{path:"pages/home"}},
  {path:'', component:BlogComponent, data: {type:"posts"}},

  {path:'about', component:PagePartComponent, resolve:{content: ContentResolver}, data:{path:"pages/about"}},
  {path:'archive', component:PagePartComponent, resolve:{content: ContentResolver}, data:{path:"pages/archive"}},

  {path:'posts', component:TypeEntryListComponent, resolve: {entries: TypeEntryResolver}, data: {type:"posts"}},
  {path:'posts/:type/:name', component:PagePartComponent, resolve: {content: ContentResolver}},

  {path:'articles/:name', component:PagePartComponent, resolve: {content: ContentResolver}},
  {path:'articles', component:TypeEntryListComponent, resolve: {entries: TypeEntryResolver}, data: {type:"articles"}},

  {path:'snippets/:name', component:PagePartComponent, resolve: {content: ContentResolver}},
  {path:'snippets', component:TypeEntryListComponent, resolve: {entries: TypeEntryResolver}, data: {type:"snippets"}},

  {path:'pages/:name', component:PagePartComponent, resolve: {content: ContentResolver}},
  {path:'pages/:sub/:name', component:PagePartComponent, resolve: {content: ContentResolver}},
  {path:'pages', component:TypeEntryListComponent, resolve: {entries: TypeEntryResolver}, data: {type:"pages"}},

  {path:'projects/:name', component:PagePartComponent, resolve: {content: ContentResolver}},
  {path:'projects', component:TypeEntryListComponent, resolve: {entries: TypeEntryResolver}, data: {type:"projects"}},

  {path:'tags', component:TagListComponent},
  {path:'tag/:name', component:TagEntryListComponent, resolve: {entries: TagEntryResolver}},

  {path:'categories', component:CategoryListComponent},
  {path:'category/:name', component:CategoryEntryListComponent, resolve: {entries: CategoryEntryResolver}},

  {path:'sb/grouplist', component: GroupListTestbedComponent},

  {path: 'sb/dummy', component: DummyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
