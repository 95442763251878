import { Component, OnInit } from '@angular/core';
import { IndexEntry } from '../../../../model/index-entry';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tag-entry-list',
  templateUrl: './tag-entry-list.component.html',
  styleUrls: ['./tag-entry-list.component.scss']
})
export class TagEntryListComponent implements OnInit {
  tagName: string ;
  entries: Array<IndexEntry> ;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.tagName = this.route.snapshot.data.tag || this.route.snapshot.params.name ;
    this.entries = this.route.snapshot.data.entries
  }

}
