import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../../service/content.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page',
  templateUrl: './page-part.component.html',
})
export class PagePartComponent implements OnInit {
  html: SafeHtml ;
  data: any = {} ;
  valid: boolean = false ;

  constructor(private contentService: ContentService,
              private domSanitizer: DomSanitizer,
              private activatedRoute: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    const content =  this.activatedRoute.snapshot.data.content ;
    if (content) {
      this.html = this.domSanitizer.bypassSecurityTrustHtml (content.content);
      this.data = content.data;
    }
  }

}
