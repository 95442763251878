import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ContentDao } from './dao/content.dao';
import { AdminModule } from './parts/admin/admin.module';
import { ContentModule } from './parts/content/content.module';
import { ListModule } from './parts/list/list.module';
import { MomentModule } from 'ngx-moment';
import { BlogComponent } from './parts/blog/blog.component';
import { PaginatorModule } from './components/paginator/paginator.module';
import { SandboxModule } from './parts/sandbox/sandbox.module';

@NgModule({
  declarations: [
    AppComponent,
    BlogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    MomentModule,

    PaginatorModule,

    AdminModule,
    ContentModule,
    ListModule,
    SandboxModule,

  ],
  providers: [
    ContentDao
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
