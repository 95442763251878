import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentDao } from './dao/content.dao';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  name = '';
  title = '';

  constructor(private router: Router, private contentDao: ContentDao) {
  }

  public ngOnInit(): void {
    this.contentDao.getSiteConfig().subscribe(
      (config) => {
        this.name = config.name ;
        this.title = config.title ;
      }
    )
  }


}
