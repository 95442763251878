import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IndexEntry } from '../../../../model/index-entry';

@Component({
  selector: 'app-type-list',
  templateUrl: './category-entry-list.component.html',
  styleUrls: ['./category-entry-list.component.scss']
})
export class CategoryEntryListComponent implements OnInit {
  categoryName: string ;
  entries: Array<IndexEntry> ;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.categoryName = this.route.snapshot.data.category || this.route.snapshot.params.name ;
    this.entries = this.route.snapshot.data.entries
  }

}
