import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContentService } from '../content.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentResolver implements Resolve<any> {


  constructor(private contentService: ContentService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let path = route.data.path || route.url.join("/") ;

    return this.contentService.getContent(path).pipe(
      tap((result) => console.debug ("ContentResolver: ", result))
    )
  }


}
