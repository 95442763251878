import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IndexEntry } from '../../../model/index-entry';
import * as moment from "moment"
import * as _ from 'lodash'
import { GroupData, GroupItem, GroupSection } from '../../../components/group-filter-list/group-filter-list.model';

@Component({
  selector: 'app-type-list',
  templateUrl: './type-entry-list.component.html',
  styleUrls: ['./type-entry-list.component.scss']
})
export class TypeEntryListComponent implements OnInit {
  typeName: string ;
  entries: Array<IndexEntry> ;
  groupData: GroupData = new GroupData() ;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.typeName = this.route.snapshot.data.type || this.route.url[0] ;
    this.entries = this.route.snapshot.data.entries ;

    let grouped = _.groupBy(this.entries, (entry) => moment(entry.date).startOf('month').format("MMMM YYYY"));
    console.info ("GROUPED: ", grouped) ;


    for (let key in grouped) {
      let section:GroupSection = this.groupData.sections[key] ;
      if (!section) {
        section = new GroupSection() ;
        section.name = key ;
        this.groupData.sections.push(section) ;
      }

      grouped[key].forEach((ie:IndexEntry) => {
          section.items.push({name:ie.title, path:"/"+ie.path})
      })

    }
  }

  public dateBucket(entry: IndexEntry) {
    return moment(entry.date).startOf('month').format("MMMM YYYY");
  }
}
