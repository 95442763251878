import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagePartComponent } from './page/page-part.component';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';

@NgModule({
  declarations: [PagePartComponent],
  imports: [
    CommonModule,
    RouterModule,
    MomentModule
  ],
  exports: [
    PagePartComponent
  ]
})
export class ContentModule { }
